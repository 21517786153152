<div class="landing-categories">
  <div>
    <p class="landing-categories__header heading1--extra-bold">
      {{ 'LANDING.CATEGORIES.HEADER.TEXT_1' | translate }}
      <span class="landing-categories__header--emph heading1--extra-bold">
        {{ 'LANDING.CATEGORIES.HEADER.TEXT_2' | translate }}
      </span>
      {{ 'LANDING.CATEGORIES.HEADER.TEXT_3' | translate }}
      <span class="landing-categories__header--emph heading1--extra-bold">{{
        'LANDING.CATEGORIES.HEADER.TEXT_4' | translate
      }}</span>
    </p>
    <p class="landing-categories__subheader body1--medium">
      {{ 'LANDING.CATEGORIES.SECONDARY_HEADER' | translate }}
    </p>
  </div>
  <div *ngIf="parentWidthComputed">
    <div #cardWrapperRefTemplate class="landing-categories__card-container">
      <div #animateRefTemplate class="landing-categories__card-container__a">
        <app-landing-categories-card
          *ngFor="let category of landing_categories"
          [category]="category"
        ></app-landing-categories-card>
      </div>
      <div class="landing-categories__card-container__b">
        <app-landing-categories-card
          *ngFor="let category of landing_categories"
          [category]="category"
        ></app-landing-categories-card>
      </div>
    </div>
  </div>
</div>
