import { NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-landing-services',
  templateUrl: './landing-services.component.html',
  styleUrls: ['./landing-services.component.scss'],
  standalone: true,
  imports: [NgFor, TranslateModule],
})
export class LandingServicesComponent {
  assetsPath = 'assets/img/landing/services/';

  cardsContent = [
    {
      header: 'LANDING.SERVICES.CARD.1.HEADER',
      text: 'LANDING.SERVICES.CARD.1.TEXT',
      image: `${this.assetsPath}storage.svg`,
      alt: 'taager storage services',
    },
    {
      header: 'LANDING.SERVICES.CARD.2.HEADER',
      text: 'LANDING.SERVICES.CARD.2.TEXT',
      image: `${this.assetsPath}shipping.svg`,
      alt: 'taager shipping services',
    },
    {
      header: 'LANDING.SERVICES.CARD.3.HEADER',
      text: 'LANDING.SERVICES.CARD.3.TEXT',
      image: `${this.assetsPath}money.svg`,
      alt: 'taager wallet services',
    },
  ];
}
