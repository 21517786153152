<div class="header">
  <div class="header__rightside-container">
    <button
      aria-label="hamburgerMenuButton"
      alt="hamburger-menu-btn"
      class="header__hamburger-menu-button"
      (click)="onToggleSideNav()"
    >
      <img
        loading="lazy"
        class="header__hamburger-menu-button__icon"
        src="{{ headerAssetsPath + 'mobile-header-icons/hamburger-menu.svg' }}"
        width="32"
        height="32"
        alt=""
      />
    </button>
    <div class="header__logo">
      <a [routerLink]="appURLs.LANDING_URL">
        <img
          loading="lazy"
          src="{{ headerAssetsPath + 'taager-logo-2022.svg' }}"
          title="Taager Logo"
          alt="The logo of Taager website"
          class="header__logo__image--desktop"
          aria-label="taager homepage"
        />
      </a>
      <a [routerLink]="appURLs.LANDING_URL" aria-label="taager homepage">
        <img
          loading="lazy"
          src="{{ headerAssetsPath + 'LogoIcon.svg' }}"
          title="Taager Logo"
          alt="The logo of Taager website"
          class="header__logo__image--mobile"
        />
      </a>
    </div>
  </div>
  <button
    class="header__user-dropdown-btn"
    (click)="onMyAccountClicked()"
    mat-button
    [matMenuTriggerFor]="accountMenu"
    data-test-id="my-account"
  >
    <img src="{{ headerAssetsPath + 'businessman.svg' }}" />
    <p class="body1--medium header__user-dropdown-btn__text">
      {{ 'LANDING.HEADER.MY_ACCOUNT' | translate }}
    </p>
    <i class="icon icon-header-icons-chevron-down"></i>
    <mat-menu #accountMenu="matMenu" xPosition="before">
      <div class="header__user-dropdown-body">
        <button
          class="header__user-dropdown-body__login-btn body1--medium"
          (click)="onSigninClicked()"
          data-test-id="login-button"
        >
          {{ 'LANDING.HEADER.LOGIN_BTN' | translate }}
        </button>
        <p class="header__user-dropdown-body__register-text body2--regular">
          {{ 'LANDING.HEADER.NO_ACCOUNT' | translate }}
          <span
            class="header__user-dropdown-body__register-link body2--bold"
            (click)="onSignupClicked()"
          >
            {{ 'LANDING.HEADER.CREATE_NEW_ACCOUNT' | translate }}
          </span>
        </p>
      </div>
    </mat-menu>
  </button>
</div>
