<div class="wrapper">
  <div class="wrapper__section-one">
    <div class="wrapper__section-one__a">
      <div class="wrapper__section-one__a__mobile">
        <img
          class="wrapper__section-one__a__mobile__img"
          src="{{ assetsPath + 'mobile.svg' }}"
          alt="mobile"
        />
      </div>
    </div>
    <div class="wrapper__section-one__b">
      <div class="wrapper__section-one__b__instruction">
        <p class="wrapper__section-one__b__instruction__text heading1--extra-bold">
          {{ 'LANDING.DOWNLOAD.TEXT_1' | translate }}
          <span class="wrapper__section-one__b__instruction__text--styled">{{
            'LANDING.DOWNLOAD.TEXT_2' | translate
          }}</span>
          {{ 'LANDING.DOWNLOAD.TEXT_3' | translate }}
          <span class="wrapper__section-one__b__instruction__text--styled">{{
            'LANDING.DOWNLOAD.TEXT_4' | translate
          }}</span>
        </p>
        <a
          href="{{ appURLs.ANDROID_APP_URL }}"
          class="wrapper__section-one__b__instruction__action"
          target="_blank"
          (click)="onAndroidDowloadNowClicked()"
          aria-label="download taager mobile android app"
        >
          <div class="wrapper__section-one__b__instruction__action__text">
            <div class="wrapper__section-one__b__instruction__action__text__a body2--medium">
              {{ 'LANDING.DOWNLOAD.TEXT_5' | translate }}
            </div>
            <div class="wrapper__section-one__b__instruction__action__text__b">
              <img src="{{ assetsPath + 'google-play-text.svg' }}" alt="taager google play" />
            </div>
          </div>
          <div class="wrapper__section-one__b__instruction__action__icon">
            <img src="{{ assetsPath + 'google-play-1.svg' }}" alt="taager google play download" />
          </div>
        </a>
      </div>
      <div class="wrapper__section-one__b__arrow">
        <img
          class="wrapper__section-one__b__arrow__img"
          src="{{ assetsPath + 'arrow-1.svg' }}"
          alt="arrow"
        />
      </div>
    </div>
  </div>
  <div class="wrapper__section-two">
    <div class="wrapper__section-two__a">
      <p class="wrapper__section-two__a__text heading1--extra-bold">
        {{ 'LANDING.DOWNLOAD.TEXT_6' | translate }} <br />
        {{ 'LANDING.DOWNLOAD.TEXT_7' | translate }}
        <span class="wrapper__section-two__a__text--styled">{{
          'LANDING.DOWNLOAD.TEXT_8' | translate
        }}</span>
      </p>
    </div>
    <a
      routerLink="{{ appURLs.REGISTER_V2_URL }}"
      class="wrapper__section-two__b heading2--bold"
      (click)="onSignupClicked()"
      aria-label="go to taager registration page"
      >{{ 'LANDING.DOWNLOAD.BUTTON' | translate }}</a
    >
  </div>
</div>
