<div *ngIf="selectedMarket === 'eg'" class="special-container">
  <div class="special-container__header">
    <img
      class="special-container__header__img"
      src="{{ assetsPath + 'header-arrow.svg' }}"
      alt="arrow pointing at special about taager section title"
    />
    <p class="content-main-text heading1--extra-bold">{{ 'LANDING.SPECIAL.HEADER' | translate }}</p>
  </div>
  <div class="special-container__top">
    <div class="special-container__top__right card-container">
      <div class="column">
        <p class="text-with-bg heading2--bold">{{ 'LANDING.SPECIAL.CARD.1.HEADER' | translate }}</p>
        <img
          class="mobile"
          src="{{ assetsPath + 'change-price.svg' }}"
          alt="customizing price demo"
        />
        <p class="content-main-text body1--medium">
          <img src="{{ assetsPath + 'tick.svg' }}" alt="golden check box" class="tick-bg" />
          {{ 'LANDING.SPECIAL.CARD.1.TEXT_1' | translate }}
        </p>
      </div>
      <img
        class="desktop"
        src="{{ assetsPath + 'change-price.svg' }}"
        alt="customizing price demo"
      />
    </div>
    <div class="special-container__top__left card-container">
      <div class="column">
        <p class="text-with-bg heading2--bold">{{ 'LANDING.SPECIAL.CARD.2.HEADER' | translate }}</p>
        <div class="special-container__top__left__img">
          <img
            class="special-container__top__left__img__sa mobile-country"
            src="{{ assetsPath + 'cart-sa.svg' }}"
            alt="KSA cart snippet"
          />
          <img
            class="special-container__top__left__img__eg mobile-country"
            src="{{ assetsPath + 'cart-eg.svg' }}"
            alt="Egypt cart snippet"
          />
        </div>
        <p class="content-main-text body1--medium">
          <img src="{{ assetsPath + 'tick.svg' }}" class="tick-bg" alt="golden check box" />
          {{ 'LANDING.SPECIAL.CARD.2.TEXT_1' | translate }}
        </p>
        <p class="content-main-text body1--medium">
          <img src="{{ assetsPath + 'tick.svg' }}" class="tick-bg" alt="golden check box" />{{
            'LANDING.SPECIAL.CARD.2.TEXT_2' | translate
          }}
        </p>
      </div>
      <div class="special-container__top__left__img column">
        <img
          class="special-container__top__left__img__sa desktop"
          src="{{ assetsPath + 'cart-sa.svg' }}"
          alt="KSA cart snippet"
        />
        <img
          class="special-container__top__left__img__eg desktop"
          src="{{ assetsPath + 'cart-eg.svg' }}"
          alt="Egyptian cart snippet"
        />
      </div>
    </div>
  </div>
  <div class="special-container__bottom">
    <div class="special-container__bottom__right card-container">
      <div class="column">
        <div class="special-container__bottom__right__header">
          <p class="text-with-bg heading2--bold">
            {{ 'LANDING.SPECIAL.CARD.3.HEADER' | translate }}
          </p>
          <img
            class="special-container__bottom__right__header__img"
            src="{{ assetsPath + 'mob-companies.svg' }}"
            alt="taager e-wallet integrations"
          />
        </div>
        <img
          class="mobile"
          src="{{ assetsPath + 'withdraw.svg' }}"
          alt="how to easily withdraw your money from taager"
        />
        <p class="content-main-text body1--medium">
          <img src="{{ assetsPath + 'tick.svg' }}" class="tick-bg" alt="golden check box" />
          {{ 'LANDING.SPECIAL.CARD.3.TEXT_1' | translate }}
        </p>
        <p class="content-main-text body1--medium">
          <img src="{{ assetsPath + 'tick.svg' }}" class="tick-bg" alt="golden check box" />
          {{ 'LANDING.SPECIAL.CARD.3.TEXT_2' | translate }}
        </p>
      </div>
      <img
        class="desktop"
        src="{{ assetsPath + 'withdraw.svg' }}"
        alt="how to easily withdraw your money from taager"
      />
    </div>
    <div class="special-container__bottom__left card-container">
      <div class="column">
        <p class="text-with-bg heading2--bold">{{ 'LANDING.SPECIAL.CARD.4.HEADER' | translate }}</p>
        <img
          class="special-container__bottom__left__img mobile"
          src="{{ assetsPath + 'incentive.svg' }}"
          alt="taager special incentive program"
        />
        <p class="content-main-text body1--medium">
          <img src="{{ assetsPath + 'tick.svg' }}" class="tick-bg" alt="golden check box" />
          {{ 'LANDING.SPECIAL.CARD.4.TEXT_1' | translate }}
        </p>
      </div>
      <img
        class="special-container__bottom__left__img desktop"
        src="{{ assetsPath + 'incentive.svg' }}"
        alt="taager special incentive program"
      />
    </div>
  </div>
</div>
<div *ngIf="selectedMarket !== 'eg'" class="special-container">
  <div class="special-container__header">
    <img
      class="special-container__header__img"
      src="{{ assetsPath + 'header-arrow.svg' }}"
      alt="arrow pointing at special about taager section title"
    />
    <p class="content-main-text heading1--extra-bold">{{ 'LANDING.SPECIAL.HEADER' | translate }}</p>
  </div>
  <div class="special-container__top">
    <div class="sa-first-card card-container-sa">
      <p class="text-with-bg heading2--bold">{{ 'LANDING.SPECIAL.CARD.1.HEADER' | translate }}</p>
      <img src="{{ assetsPath + 'change-price-sa.svg' }}" alt="customizing price demo" />
      <p class="content-main-text body1--medium">
        <img src="{{ assetsPath + 'tick.svg' }}" alt="golden check box" class="tick-bg" />
        {{ 'LANDING.SPECIAL.CARD.1.TEXT_1' | translate }}
      </p>
    </div>
    <div class="sa-second-card card-container-sa">
      <p class="text-with-bg heading2--bold">{{ 'LANDING.SPECIAL.CARD.2.HEADER' | translate }}</p>
      <img
        class="special-container__top__left__img__sa"
        src="{{ assetsPath + 'cart-sa.svg' }}"
        alt="KSA cart snippet"
      />
      <img
        class="special-container__top__left__img__eg"
        src="{{ assetsPath + 'cart-eg.svg' }}"
        alt="Egyptian cart snippet"
      />
      <p class="content-main-text body1--medium">
        <img src="{{ assetsPath + 'tick.svg' }}" class="tick-bg" alt="golden check box" />
        {{ 'LANDING.SPECIAL.CARD.2.TEXT_1' | translate }}
      </p>
      <p class="content-main-text body1--medium">
        <img src="{{ assetsPath + 'tick.svg' }}" class="tick-bg" alt="golden check box" />{{
          'LANDING.SPECIAL.CARD.2.TEXT_2' | translate
        }}
      </p>
    </div>
    <div class="sa-third-card card-container-sa">
      <div class="special-container__bottom__right__header">
        <p class="text-with-bg heading2--bold">
          {{ 'LANDING.SPECIAL.CARD.3.HEADER' | translate }}
        </p>
        <img
          class="special-container__bottom__right__header__img"
          src="{{ assetsPath + 'payoneer.svg' }}"
          alt="taager e-wallet integrations"
        />
      </div>
      <img
        *ngIf="selectedMarket === 'sa'"
        src="{{ assetsPath + 'withdraw-sa.svg' }}"
        alt="how to easily withdraw your money from taager"
      />
      <img
        *ngIf="selectedMarket === 'ae'"
        src="{{ assetsPath + 'withdraw-ae.svg' }}"
        alt="how to easily withdraw your money from taager"
      />
      <p class="content-main-text body1--medium">
        <img src="{{ assetsPath + 'tick.svg' }}" class="tick-bg" alt="golden check box" />
        {{ 'LANDING.SPECIAL.CARD.3.TEXT_1' | translate }}
      </p>
      <p class="content-main-text body1--medium">
        <img src="{{ assetsPath + 'tick.svg' }}" class="tick-bg" alt="golden check box" />
        {{ 'LANDING.SPECIAL.CARD.3.TEXT_2' | translate }}
      </p>
    </div>
  </div>
</div>
