import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { resolveUserLandingUtility } from 'src/app/presentation/shared/utilities/resolve-user-landing.utility';

@Component({
  selector: 'app-landing-special-taager',
  templateUrl: './landing-special-taager.component.html',
  styleUrls: ['./landing-special-taager.component.scss'],
  standalone: true,
  imports: [NgIf, TranslateModule],
})
export class LandingSpecialTaagerComponent implements OnInit {
  public assetsPath = 'assets/img/landing/special/';

  public selectedMarket: string;

  ngOnInit(): void {
    this._resolveSelectedMarket();
  }

  private _resolveSelectedMarket(): void {
    this.selectedMarket = resolveUserLandingUtility().code;
  }
}
