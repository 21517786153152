import { NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LANDING_CONSTS } from 'src/app/presentation/shared/constants';
import { LandingCategoriesCardComponent } from './landing-categories-card/landing-categories-card.component';

@Component({
  selector: 'app-landing-categories',
  templateUrl: './landing-categories.component.html',
  styleUrls: ['./landing-categories.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, LandingCategoriesCardComponent, TranslateModule],
})
export class LandingCategoriesComponent implements AfterViewInit {
  @ViewChild('cardWrapperRefTemplate', { static: false })
  private _cardWrapperRefTemplate: ElementRef<HTMLDivElement>;

  @ViewChild('animateRefTemplate', { static: false })
  private _animateRefTemplate: ElementRef<HTMLDivElement>;

  landing_categories = LANDING_CONSTS.LANDING_CATEGORIES;

  public parentWidthComputed: boolean;

  constructor(private _renderer2: Renderer2, private _changeDetectorRef: ChangeDetectorRef) {
    this.parentWidthComputed = false;
  }

  ngAfterViewInit(): void {
    this._computeParentWidth();
    this._listenForMouseOverOnCardWrapper();
  }

  private _computeParentWidth(): void {
    const minChildWidth = 160;
    const childPaddingInPx = 12;
    const childMarginRightInPx = 20;
    const minChildSpaceInPx: number = minChildWidth + childPaddingInPx + childMarginRightInPx;
    const cummulativeParentWidth: number =
      minChildSpaceInPx * this.landing_categories.length + childMarginRightInPx;
    this.parentWidthComputed = true;
    this._changeDetectorRef.detectChanges();
    this._applyStyleToElement(
      this._cardWrapperRefTemplate.nativeElement,
      'width',
      `${cummulativeParentWidth}px`,
    );
  }

  private _listenForMouseOverOnCardWrapper(): void {
    this._cardWrapperRefTemplate.nativeElement.addEventListener('mouseenter', () => {
      this._applyStyleToElement(
        this._animateRefTemplate.nativeElement,
        'animation-play-state',
        'paused',
      );
    });

    this._cardWrapperRefTemplate.nativeElement.addEventListener('mouseleave', () => {
      this._applyStyleToElement(
        this._animateRefTemplate.nativeElement,
        'animation-play-state',
        'running',
      );
    });
  }

  private _applyStyleToElement(element: HTMLDivElement, style: string, value: string): void {
    this._renderer2.setStyle(element, style, value);
  }
}
