import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { provideRouter } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from '../../components/footer/footer.component';
import { DownloadAndJoinTaagerComponent } from './components/landing/download-and-join-taager/download-and-join-taager.component';
import { FaqComponent } from './components/landing/faq/faq.component';
import { QuestionBoxComponent } from './components/landing/faq/question-box/question-box.component';
import { LandingBannerComponent } from './components/landing/landing-banner/landing-banner.component';
import { LandingCategoriesCardComponent } from './components/landing/landing-categories/landing-categories-card/landing-categories-card.component';
import { LandingCategoriesComponent } from './components/landing/landing-categories/landing-categories.component';
import { LandingServicesComponent } from './components/landing/landing-services/landing-services.component';
import { LandingSpecialTaagerComponent } from './components/landing/landing-special-taager/landing-special-taager.component';
import { LandingTestimonialsComponent } from './components/landing/landing-testimonials/landing-testimonials.component';
import { LandingComponent } from './components/landing/landing.component';
import { LandingHeaderComponent } from './components/logged-out-layout/logged-out-header/logged-out-header.component';
import { LoggedOutLayoutComponent } from './components/logged-out-layout/logged-out-layout.component';
import { DownloadAppAndroidClickComponent } from './components/shared/download-app-android-click/download-app-android-click.component';
import { DownloadAppIosClickComponent } from './components/shared/download-app-ios-click/download-app-ios-click.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    MatSidenavModule,
    TranslateModule,
    MatMenuModule,
    LoggedOutLayoutComponent,
    LandingHeaderComponent,
    FooterComponent,
    LandingComponent,
    LandingCategoriesComponent,
    LandingCategoriesCardComponent,
    LandingServicesComponent,
    FaqComponent,
    QuestionBoxComponent,
    LandingSpecialTaagerComponent,
    DownloadAndJoinTaagerComponent,
    TermsAndConditionsComponent,
    LandingTestimonialsComponent,
    LandingBannerComponent,
    DownloadAppAndroidClickComponent,
    DownloadAppIosClickComponent,
  ],
  providers: [
    provideRouter([
      {
        path: '',
        component: LoggedOutLayoutComponent,
        children: [
          {
            path: '',
            component: LandingComponent,
          },
        ],
      },
    ]),
  ],
})
export class LoggedOutLayoutModule {}
