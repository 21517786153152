import { NgFor, NgStyle, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LANDING_MERCHANT_TESTEMONIALS, Testimonial } from 'src/app/presentation/shared/constants';

@Component({
  selector: 'app-landing-testimonials',
  templateUrl: './landing-testimonials.component.html',
  styleUrls: ['./landing-testimonials.component.scss'],
  standalone: true,
  imports: [NgFor, NgTemplateOutlet, NgStyle, TranslateModule],
})
export class LandingTestimonialsComponent implements AfterViewInit {
  public assetsPath: string;

  public testimonials: Array<Testimonial>;

  @ViewChild('wrapperTemplate', { static: true })
  private _wrapperTemplate!: ElementRef<HTMLDivElement>;

  @ViewChild('wrapperATemplate', { static: true })
  private _wrapperATemplate!: ElementRef<HTMLDivElement>;

  public mockWidth: number;

  constructor(private _renderer2: Renderer2) {
    this.assetsPath = 'assets/img/landing/testimonials/';
    this.testimonials = LANDING_MERCHANT_TESTEMONIALS;
  }

  ngAfterViewInit(): void {
    this._listenForMouseOverOnWrapper();
  }

  private _listenForMouseOverOnWrapper(): void {
    this._wrapperTemplate.nativeElement.addEventListener('mouseenter', () => {
      this._applyStyleToElement(
        this._wrapperATemplate.nativeElement,
        'animation-play-state',
        'paused',
      );
    });
    this._wrapperTemplate.nativeElement.addEventListener('mouseleave', () => {
      this._applyStyleToElement(
        this._wrapperATemplate.nativeElement,
        'animation-play-state',
        'running',
      );
    });
  }

  private _applyStyleToElement(element: HTMLDivElement, style: string, value: string): void {
    this._renderer2.setStyle(element, style, value);
  }
}
