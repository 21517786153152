import { Component, Inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { LandingEventsService } from 'src/app/presentation/shared/containers/logged-out-layout/services/landing-events.service';

@Component({
  selector: 'app-download-and-join-taager',
  templateUrl: './download-and-join-taager.component.html',
  styleUrls: ['./download-and-join-taager.component.scss'],
  standalone: true,
  imports: [RouterLink, TranslateModule],
})
export class DownloadAndJoinTaagerComponent {
  public assetsPath: string;

  constructor(
    private _landingEventsService: LandingEventsService,
    @Inject(appUrlsConstantsInjectionToken) public appURLs: { [url: string]: string },
  ) {
    this.assetsPath = 'assets/img/landing/download-and-join-taager/';
  }

  onSignupClicked() {
    this._landingEventsService.sendLandingMixpanelEvent('sign_up_button_click', {
      $button_location: 'start_now_section',
    });
    this._doLogSignupClickedOnGtag();
  }

  onAndroidDowloadNowClicked() {
    this._landingEventsService.sendLandingMixpanelEvent('android_download_now_button_click', {
      $button_location: 'start_now_section',
    });
    this._doLogGtagAndroidDownloadNowEvent();
  }

  private async _doLogSignupClickedOnGtag() {
    const payload = {
      'Button Location': 'start_now',
    };
    this._landingEventsService.sendGtagMixpanelEvent('signup_button_click', payload);
  }

  private async _doLogGtagAndroidDownloadNowEvent() {
    const payload = {
      'Button Location': 'download section',
    };
    this._landingEventsService.sendGtagMixpanelEvent('download_app_android_click', payload);
  }
}
