import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-landing-categories-card',
  templateUrl: './landing-categories-card.component.html',
  styleUrls: ['./landing-categories-card.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class LandingCategoriesCardComponent {
  @Input() category: { name: string; iconClass: string };
}
