import { Component, Inject } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { LandingEventsService } from 'src/app/presentation/shared/containers/logged-out-layout/services/landing-events.service';
import { FooterComponent } from '../../../../components/footer/footer.component';
import { LandingHeaderComponent } from './logged-out-header/logged-out-header.component';

@Component({
  selector: 'app-logged-out-layout',
  templateUrl: './logged-out-layout.component.html',
  styleUrls: ['./logged-out-layout.component.scss'],
  standalone: true,
  imports: [
    MatSidenavModule,
    LandingHeaderComponent,
    RouterOutlet,
    FooterComponent,
    TranslateModule,
  ],
})
export class LoggedOutLayoutComponent {
  isMenuOpen: boolean;

  headerAssetsPath = 'assets/img/header-icons/';

  genericAssetsPath = 'assets/img/';

  constructor(
    private _landingEventsService: LandingEventsService,
    private _router: Router,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
  ) {
    this.isMenuOpen = false;
  }

  onCloseSideNav(): void {
    this.isMenuOpen = false;
  }

  onToggleSideNav(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  onSignupClicked(): void {
    this._router.navigate([this._appURLs.REGISTER_V2_URL]);
    this._landingEventsService.sendLandingMixpanelEvent('landing-register-click', {
      $button_location: 'sidenav',
    });
    this._doLogSignupClickedOnGtag();
  }

  onLoginClicked(): void {
    this._router.navigate([this._appURLs.LOGIN_V2_URL]);
    this._landingEventsService.sendLandingMixpanelEvent('landing-login-click', {
      $button_location: 'sidenav',
    });
  }

  private async _doLogSignupClickedOnGtag(): Promise<void> {
    const payload = {
      'Button Location': 'sidenav',
    };
    this._landingEventsService.sendGtagMixpanelEvent('signup_button_click', payload);
  }
}
