<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #sidenav
    autoFocus="false"
    mode="over"
    [(opened)]="isMenuOpen"
    class="sidenav"
    fixedInViewport="true"
  >
    <section class="sidenav__header">
      <img
        loading="lazy"
        src="{{ headerAssetsPath + 'taager-logo-2022.svg' }}"
        alt="Taager's logo"
        class="sidenav__header__logo"
        width="200"
        height="36"
      />
      <button class="sidenav__header__close-button" (click)="onCloseSideNav()">
        <img
          loading="lazy"
          src="{{ genericAssetsPath + 'close.svg' }}"
          alt="sidenav close button"
          class="sidenav__header__close-button__icon"
          width="16"
          height="16"
        />
      </button>
    </section>
    <section class="sidenav__content">
      <button class="sidenav__content__signup-btn body1--medium" (click)="onLoginClicked()">
        {{ 'LANDING.HEADER.LOGIN_BTN' | translate }}
      </button>
      <p class="sidenav__content__register-text body2--regular">
        {{ 'LANDING.HEADER.NO_ACCOUNT' | translate }}
        <span class="sidenav__content__register-link body2--bold" (click)="onSignupClicked()">
          {{ 'LANDING.HEADER.CREATE_NEW_ACCOUNT' | translate }}
        </span>
      </p>
    </section>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="wrapper">
      <div class="wrapper__header">
        <app-landing-header (toggleSideNav)="onToggleSideNav()"></app-landing-header>
      </div>
      <div class="wrapper__content">
        <div class="wrapper__content__landing">
          <router-outlet></router-outlet>
        </div>
        <div class="wrapper__content__footer">
          <app-footer></app-footer>
        </div>
      </div></div
  ></mat-sidenav-content>
</mat-sidenav-container>
