import { Component, OnInit } from '@angular/core';
import { LANDING_CONSTS } from 'src/app/presentation/shared/constants';
import { LandingEventsService } from 'src/app/presentation/shared/containers/logged-out-layout/services/landing-events.service';
import { DownloadAndJoinTaagerComponent } from './download-and-join-taager/download-and-join-taager.component';
import { FaqComponent } from './faq/faq.component';
import { LandingBannerComponent } from './landing-banner/landing-banner.component';
import { LandingCategoriesComponent } from './landing-categories/landing-categories.component';
import { LandingServicesComponent } from './landing-services/landing-services.component';
import { LandingSpecialTaagerComponent } from './landing-special-taager/landing-special-taager.component';
import { LandingTestimonialsComponent } from './landing-testimonials/landing-testimonials.component';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  standalone: true,
  imports: [
    LandingBannerComponent,
    LandingServicesComponent,
    LandingCategoriesComponent,
    LandingSpecialTaagerComponent,
    LandingTestimonialsComponent,
    FaqComponent,
    DownloadAndJoinTaagerComponent,
  ],
})
export class LandingComponent implements OnInit {
  faqQuestions = LANDING_CONSTS.LANDING_COMMON_QUESTIONS;

  constructor(private _landingEventsService: LandingEventsService) {}

  ngOnInit(): void {
    this._doTrackGtagLandingPageLoadEvent();
    this._doTrackMixpanelPageLoadEvent();
  }

  private async _doTrackGtagLandingPageLoadEvent() {
    this._landingEventsService.sendGtagMixpanelEvent('landing_page_load');
  }

  private _doTrackMixpanelPageLoadEvent(): void {
    this._landingEventsService.sendLandingMixpanelEvent('landing_page_load');
  }
}
