<div class="wrapper">
  <div class="wrapper__header">
    <img
      class="wrapper__header__icon"
      src="{{ assetsPath + 'arrow.svg' }}"
      alt="taager testimonials header arrow"
    />
    <span class="wrapper__header__text heading1--extra-bold">{{
      'LANDING.TESTIMONIAL.HEADER' | translate
    }}</span>
  </div>
  <div #wrapperTemplate class="wrapper__content">
    <div #wrapperATemplate class="wrapper__a">
      <div *ngFor="let testimonial of testimonials" class="wrapper__testimonial">
        <ng-template
          [ngTemplateOutlet]="testimonialElement"
          [ngTemplateOutletContext]="{ testimonial }"
        >
        </ng-template>
      </div>
    </div>
    <div class="wrapper__b">
      <div *ngFor="let testimonial of testimonials" class="wrapper__testimonial">
        <ng-template
          [ngTemplateOutlet]="testimonialElement"
          [ngTemplateOutletContext]="{ testimonial }"
        >
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #testimonialElement let-testimonial="testimonial">
  <div [ngStyle]="testimonial.style" class="wrapper__testimonial__content">
    <div class="wrapper__testimonial__content__text body1--medium">
      "{{ testimonial.textContent | translate }}"
    </div>
    <div class="wrapper__testimonial__content__user">
      <div class="wrapper__testimonial__content__user__avatar">
        <img
          src="{{ assetsPath + testimonial.user.avatar }}"
          alt="{{ 'taager testimonials ' + testimonial.user.name }}"
        />
      </div>
      <div class="wrapper__testimonial__content__user__profile">
        <div class="wrapper__testimonial__content__user__profile__name body1--medium">
          {{ testimonial.user.name | translate }}
        </div>
        <div class="wrapper__testimonial__content__user__profile__position caption1--regular">
          {{ testimonial.user.location | translate }}
        </div>
      </div>
    </div>
  </div>
</ng-template>
