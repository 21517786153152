import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { LandingEventsService } from 'src/app/presentation/shared/containers/logged-out-layout/services/landing-events.service';

@Component({
  selector: 'app-landing-header',
  templateUrl: './logged-out-header.component.html',
  styleUrls: ['./logged-out-header.component.scss'],
  standalone: true,
  imports: [RouterLink, MatMenuModule, TranslateModule],
})
export class LandingHeaderComponent {
  @Output() toggleSideNav: EventEmitter<void> = new EventEmitter<void>();

  headerAssetsPath = 'assets/img/header-icons/';

  constructor(
    private _landingEventsService: LandingEventsService,
    private _router: Router,
    @Inject(appUrlsConstantsInjectionToken) public appURLs: { [url: string]: string },
  ) {}

  onToggleSideNav(): void {
    this.toggleSideNav.emit();
  }

  onSignupClicked(): void {
    this._router.navigate([this.appURLs.REGISTER_V2_URL]);
    this._landingEventsService.sendLandingMixpanelEvent('landing-register-click', {
      $button_location: 'account-dropdown',
    });
    this._doLogSignupClickedOnGtag();
  }

  onMyAccountClicked(): void {
    this._landingEventsService.sendLandingMixpanelEvent('landing-account-click', {
      $button_location: 'header',
    });
  }

  private async _doLogSignupClickedOnGtag(): Promise<void> {
    const payload = {
      'Button Location': 'header',
    };
    this._landingEventsService.sendGtagMixpanelEvent('signup_button_click', payload);
  }

  onSigninClicked(): void {
    this._router.navigate([this.appURLs.LOGIN_V2_URL]);
    this._landingEventsService.sendLandingMixpanelEvent('landing-login-click', {
      $button_location: 'account-dropdown',
    });
    this._doLogSignInClickedOnGtag();
  }

  private async _doLogSignInClickedOnGtag(): Promise<void> {
    this._landingEventsService.sendGtagMixpanelEvent('sign_in_button_click');
  }
}
